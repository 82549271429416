import AuthService from '@/services/auth'

const state = {
  step: 0,
  steps: {
    1: {
      step: 1,
      label: 'NAVIGATION',
      name: 'navigation',
      active: false,
      progress: 0,
    },
    2: {
      step: 2,
      label: 'CAR INFO',
      name: 'vehicle-info',
      active: false,
      progress: 0,
    },
    3: {
      step: 3,
      label: 'PHOTOS',
      name: 'photo-upload',
      active: false,
      progress: 0,
    },
    4: {
      step: 4,
      label: 'CONDITION',
      name: 'condition-info',
      active: false,
      progress: 0,
    },
    5: {
      step: 5,
      label: 'PRICING',
      active: false,
      name: 'customer-detail',
      progress: 0,
    },
    6: {
      step: 6,
      label: 'REVIEW',
      name: 'contact',
      active: false,
      progress: 0,
    },
  },
  token: null,
  fetchTokenPromise: null,
  progressStep: 1,
  lastProgressStep: 0,
  onEdit: false,
  isIdle: false,
  isShowRegoPopup: false,
  showReservePriceBanner: false,
  reminderPopupWasShown: false,
  isLoadingScreen: true,
  onEditPage: false,
}

const getters = {
  currentStep(state) {
    return state.steps[state.step];
  },
  nextStep(state) {
    return state.steps[state.step + 1];
  },
  steps(state) {
    return Object.values(state.steps);
  },
  step(state) {
    return state.step
  },
  token(state) {
    return state.token
  },
  onEdit(state) {
    return state.onEdit
  },
  isIdle(state) {
    return state.isIdle
  },
  isShowRegoPopup(state) {
    return state.isShowRegoPopup
  },
  showReservePriceBanner(state) {
    return state.showReservePriceBanner
  },
  reminderPopupWasShown(state) {
    return state.reminderPopupWasShown
  },
  isLoadingScreen(state) {
    return state.isLoadingScreen
  },
  onEditPage(state) {
    return state.onEditPage
  },
}
const actions = {
  setStep(context, step) {
    context.commit('setStep', step)
  },
  resetStep(context) {
    context.commit('resetStep')
  },
  resetProgressStep(context) {
    context.commit('resetProgressStep')
  },
  setProgress(context, add) {
    context.commit('setProgress', add)
  },
  setToken(context, token) {
    context.commit('setToken', token)
  },
  fetchToken({ commit, state }) {
    if (!state.fetchTokenPromise) {
      commit('setToken', null);
      const tokenPromise = AuthService.refreshToken()
        .then(({ data }) => {
          commit('setToken', data.token);
          commit('setFetchTokenPromise', null);
        })
        .catch(() => {
          commit('setFetchTokenPromise', null);
        });
      commit('setFetchTokenPromise', tokenPromise)
    }
    return state.fetchTokenPromise;
  },
  watchStep(context, state ) {
    context.commit('watchStep', state)
  },
  setOnEdit(context, value) {
    context.commit('setOnEdit', value)
  },
  setIsIdle(context, value) {
    context.commit('setIsIdle', value)
  },
  updateIsShowRegoPopup(context, value) {
    context.commit('updateIsShowRegoPopup', value)
  },
  setShowReservePriceBanner(context, value) {
    context.commit('setShowReservePriceBanner', value)
  },
  setReminderPopupWasShown(context, value) {
    context.commit('setReminderPopupWasShown', value)
  },
  setOnEditPage(context, value) {
    context.commit('setOnEditPage', value)
  },
  setLoadingScreen(context, value) {
    context.commit('setLoadingScreen', value)
  }
}

const mutations = {
  setStep(state, payload) {
    state.step = payload
    Object.values(state.steps).forEach(step => {
      state.steps[step.step] = step.step > payload ? {
        ...state.steps[step.step],
        active: false
      } : {
        ...state.steps[step.step],
        active: true
      };
    })
  },
  resetStep(state) {
    state.step = 0
    Object.values(state.steps).forEach(step => {
      state.steps[step.step] = {
        ...step,
        active: false,
        progress: 0
      }
    });
  },
  resetProgressStep(state) {
    state.progressStep = 1;
    state.lastProgressStep = 0;
  },
  setProgress(state, progress) {
    state.steps[state.step + 1] = {
      ...state.steps[state.step + 1],
      progress
    };
  },
  setToken(state, token) {
    state.token = token
  },
  setFetchTokenPromise(state, fetchingPromise) {
    state.fetchTokenPromise = fetchingPromise;
  },
  watchStep(state) {

    this._watcher = this.watch(
      () => state.step,
      (curVal, prevVal) => {
        if (curVal < prevVal && prevVal >= state.lastProgressStep) {
          state.lastProgressStep = prevVal;
          state.progressStep = prevVal;
        } else if (curVal > prevVal && prevVal >= state.lastProgressStep) {
          state.progressStep = curVal;
        }
      }
    );
  },
  setOnEdit(state, value) {
    state.onEdit = value
  },
  setIsIdle(state, value) {
    state.isIdle = value
  },
  updateIsShowRegoPopup(state, value) {
    state.isShowRegoPopup = value
  },
  setShowReservePriceBanner(state, value) {
    state.showReservePriceBanner = value
  },
  setReminderPopupWasShown(state, value) {
    state.reminderPopupWasShown = value
  },
  setLoadingScreen(state, value) {
    state.isLoadingScreen = value
  },
  setOnEditPage(state, value) {
    state.onEditPage = value
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}