// import { isNull, mergeWith } from 'lodash-es'
import {
	SET_PAYMENT_INFO,
	CLEAR_ALL_PAYMENT_INFO,
	SET_BUYERS_PORTAL_MAIL,
} from "./actions";
import {
	SET_PAYMENT_INFO_SUCCESS,
	CLEAR_ALL_PAYMENT_INFO_SUCCESS,
	SET_BUYERS_PORTAL_MAIL_SUCCESS,
} from "./mutations";

const initialState = {
	contactInfo: {
		firstName: '',
		lastName: '',
		addressLine1: '',
		addressLine2: '',
		suburb: '',
		state: '',
		postcode: '',
		email: '',
	},
	paymentInfo: {
		cardNumber: '',
		securityCode: '',
		expiryMonth: '',
		expiryYear: '',
		cardName: '',
	},
	token: '',
	buyersPortalMail: '',
}

const state = { ...initialState };

const getters = {
	contactInfo(state) {
		return state.contactInfo;
	},
	paymentInfo(state) {
		return state.paymentInfo;
	},
	paymentToken(state) {
		return state.token;
	},
}
const actions = {
	async [SET_PAYMENT_INFO](context, payload) {
		context.commit(SET_PAYMENT_INFO_SUCCESS, payload);
	},
	async [CLEAR_ALL_PAYMENT_INFO](context) {
		context.commit(CLEAR_ALL_PAYMENT_INFO_SUCCESS);
	},
	[SET_BUYERS_PORTAL_MAIL](context, payload) {
		context.commit(SET_BUYERS_PORTAL_MAIL_SUCCESS, payload);
	},
}
const mutations = {
	[SET_PAYMENT_INFO_SUCCESS](state, payload) {
		state.contactInfo = {...payload.contactInfo}
		state.paymentInfo = {...payload.paymentInfo}
		state.token = payload.token;
	},
	[CLEAR_ALL_PAYMENT_INFO_SUCCESS](state) {
		state.contactInfo = {
			firstName: '',
			lastName: '',
			addressLine1: '',
			addressLine2: '',
			suburb: '',
			state: '',
			postcode: '',
			email: '',
		}
		state.paymentInfo = {
			cardNumber: '',
			securityCode: '',
			expiryMonth: '',
			expiryYear: '',
			cardName: '',
		}
		state.token = ''
	},
	[SET_BUYERS_PORTAL_MAIL_SUCCESS](state, payload) {
		state.buyersPortalMail = payload;
	},
}

export default { state, actions, mutations, getters };