import { routers } from '@/helper/routers';

export default [
  {
    path: routers.CONDITION_INFO,
    name: "condition-info",
    component: () => import("@/views/condition-info/Index"),
    meta: {
      step: 1,
    },
  }
];