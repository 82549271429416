import { routers } from '@/helper/routers';

export default [
    {
        path: routers.PHOTO_GUIDE,
        name: 'photo-guide',
        component: () => import('@/views/photo-guide/Index'),
        meta: {
            nextable: true
        }
    },
]