const state = {
  uploadImage: [],
  sendPhotoLater: true,
  hasPhoto: false,
  guideCameraUsed: false,
  takePhotoWithGuidedCamera: false,
  keyFrame: '',
  isUseGuidedUpload: false,
}

const getters = {
  uploadImage (state) {
    return state.uploadImage
  },
  sendPhotoLater (state) {
    return state.sendPhotoLater
  },
  hasPhoto (state) {
    return state.hasPhoto
  },
  guideCameraUsed (state) {
    return state.guideCameraUsed
  },
  takePhotoWithGuidedCamera (state) {
    return state.takePhotoWithGuidedCamera
  },
  keyFrame (state) {
    return state.keyFrame
  },
  isUseGuidedUpload (state) {
    return state.isUseGuidedUpload
  },
}
const actions = {
  setUploadedImages (context, data) {
    context.commit('setUploadedImages', data)
  },
  clearImages (context) {
    context.commit('clearImages')
  },
  setSendPhotoLater (context, data) {
    context.commit('setSendPhotoLater', data)
  },
  clearSendPhotoLater (context) {
    context.commit('clearSendPhotoLater')
  },
  hasPhoto (context, data) {
    context.commit('hasPhoto', data)
  },
  setGuideCameraUsed (context, data) {
    context.commit('setGuideCameraUsed', data)
  },
  setTakePhotoWithGuidedCamera (context, data) {
    context.commit('setTakePhotoWithGuidedCamera', data)
  },
  setKeyFrame (context, data) {
    context.commit('setKeyFrame', data)
  },
  setIsUseGuidedUpload (context, data) {
    context.commit('setIsUseGuidedUpload', data)
  },
}

const mutations = {
  setUploadedImages (state, payload) {
    state.uploadImage = payload
  },
  clearImages (state) {
    state.uploadImage = []
  },
  setSendPhotoLater (state, payload) {
    state.sendPhotoLater = payload
  },
  clearSendPhotoLater (state) {
    state.sendPhotoLater = true
  },
  hasPhoto (state, payload) {
    state.hasPhoto = payload
  },
  setGuideCameraUsed (state, payload) {
    state.guideCameraUsed = payload
  },
  setTakePhotoWithGuidedCamera (state, payload) {
    state.takePhotoWithGuidedCamera = payload
  },
  setKeyFrame (state, payload) {
    state.keyFrame = payload
  },
  setIsUseGuidedUpload (state, payload) {
    state.isUseGuidedUpload = payload
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}