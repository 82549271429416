import {
  ADD_LOG,
  CLEAR_INTERVAL,
  CLEAR_LOG,
  GET_CONFIG_INTERVAL,
  GET_QUALIFIER_CONFIG,
} from "./actions";
import {
  ADD_LOG_SUCCESS,
  CLEAR_INTERVAL_SUCCESS,
  CLEAR_LOG_SUCCESS,
  GET_QUALIFIER_CONFIG_SUCCESS,
  SET_INTERVAL_SUCCESS,
} from "./mutations";
import qualifierService from "@/services/self-service-qualifier";

const state = {
  config: {},
  logs: {},
  interval: null,
};

const getters = {
  config(state) {
    return state.config;
  },
  logs(state) {
    return state.logs;
  },
  interval(state) {
    return state.interval;
  }
};

const actions = {
  async [GET_QUALIFIER_CONFIG](context, payload) {
    context.commit(GET_QUALIFIER_CONFIG_SUCCESS, payload);
  },
  async [ADD_LOG](context, payload) {
    context.commit(ADD_LOG_SUCCESS, payload);
  },
  async [CLEAR_LOG](context) {
    context.commit(CLEAR_LOG_SUCCESS);
  },
  async [GET_CONFIG_INTERVAL](context) {
    if (!state.interval) {
      var interval = setInterval(async () => {
        const { data } = await qualifierService.getConfig();
        context.commit(GET_QUALIFIER_CONFIG_SUCCESS, data);
      }, 60000); // fetch config each 1min
      context.commit(SET_INTERVAL_SUCCESS, interval);
    }
  },
  async [CLEAR_INTERVAL](context) {
    context.commit(CLEAR_INTERVAL_SUCCESS);
  },
};

const mutations = {
  [GET_QUALIFIER_CONFIG_SUCCESS](state, payload) {
    state.config = payload;
  },
  [ADD_LOG_SUCCESS](state, payload) {
    state.logs = { ...state.logs, ...payload };
  },
  [CLEAR_LOG_SUCCESS](state) {
    state.logs = {};
  },
  [SET_INTERVAL_SUCCESS](state, payload) {
    state.interval = payload;
  },
  [CLEAR_INTERVAL_SUCCESS](state) {
    clearInterval(state.interval);
    state.interval = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
