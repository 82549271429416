import webApi from "./api"

export class LeadUpdateService {
	async updateLead(data) {
		return webApi.post('lead-update-service/update', data, { timeout: 120000 })
	}
	async reportIssues(data) {
		return webApi.post('lead-update-service/report-issues', data)
	}
	async saveTempData(data) {
		return webApi.post('lead-update-service/update', data, { timeout: 60000 })
	}
	async updateRego(data) {
		return webApi.post('lead-update-service/update-rego', data)
	}
}

const leadUpdateService = new LeadUpdateService()

export default leadUpdateService