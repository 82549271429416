const state = {
  email: "",
  locid: "",
};

const getters = {
  universalLoginEmail(state) {
    return state.email;
  },
  locid(state) {
    return state.locid;
  },
};

const actions = {
  setEmail(context, value) {
    context.commit("setEmail", value);
  },
  clearEmail(context) {
    context.commit("clearEmail");
  },
  setLocid(context, value) {
    context.commit("setLocid", value);
  },
  clearLocid(context) {
    context.commit("clearLocid");
  },
};

const mutations = {
  setEmail(state, value) {
    state.email = value;
  },
  clearEmail(state) {
    state.email = "";
  },
  setLocid(state, value) {
    state.locid = value;
  },
  clearLocid(state) {
    state.locid = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
