import { routers } from '@/helper/routers'

export default [
  {
    path: routers.PHOTO_UPLOAD,
    name: 'photo-upload',
    component: () => import('@/views/photo-upload/Index'),
    meta: {
      step: 1,
    },
  },
  {
    path: routers.PHOTO,
    name: 'photo',
    component: () => import('@/views/photo-upload/Index'),
    meta: {
      step: 1,
    },
  },
];