import { routers } from '@/helper/routers';

export default [
    {
        path: routers.SALE_INFO,
        name: 'contact',
        component: () => import('@/views/contact/Index'),
        meta: {
            step: 1,
        }
    },
]