import webApi from "./api";
import * as dayjs from "dayjs";
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export class QualifierService {
  async getConfig() {
    return webApi.get("self-service/get-config");
  }

  getNow() {
    return dayjs().format("YYYYMMDDHHmm");
  }

  compareDates = (d1, compare, d2, unit = "day") => {
    d1 = dayjs(d1).isValid() ? d1 : dayjs(d1);
    d2 = dayjs(d2).isValid() ? d2 : dayjs(d2);
    switch (compare) {
      case ">":
        return d1.isAfter(d2, unit);
      case ">=":
        return d1.isSameOrAfter(d2, unit);
      case "<=":
        return d1.isSameOrBefore(d2, unit);
      case "<":
        return d1.isBefore(d2, unit);
      case "=":
        return d1.isSame(d2, unit);
      case "!=":
        return !d1.isSame(d2, unit);
      default:
        throw new Error("Unsupported compare operator for dates !");
    }
  };

  titleInfoMap() {
    return {
      make: "Make",
      model: "Model",
      year: "Year",
      badge: "Variant",
      series: "Series",
      color: "Color",
      bodyType: "Body",
      fuelType: "Fuel",
      transmission: "Transmission",
      model_year: "Model Year",
      engineType: "Engine",
    };
  }

  async track(step, data, config) {
    let reason = {
      step1: [],
      step2: [],
      step3: [],
      step4: [],
      step5: [],
      step6: [],
    };
    switch (step) {
      case 1:
        if (config.rego.switch) {
          if (!data.regoSuccess) {
            reason["step1"] = [
              `${this.getNow()}:${
                data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Rego Not Found"`,
            ];
          }
        }
        return {
          reason,
        };

      case 2:
        Object.keys(config).forEach((item) => {
          const emptyApproved = item === "badge" || item === "series" || item === "model_year";
          if (config[item].switch) {
            if (
              (data[item] === "Not sure" ||
                (data[item] === "" && !emptyApproved))
            ) {
              reason["step2"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${data.rego}:"${
                  this.titleInfoMap()[item]
                } missing or not sure"`
              );
            }
          }
        });
        return {
          reason,
        };

      case 3:
        // photos upload
        if (config.photos.switch) {
          if (data.photos < Number(config.photos.conf)) {
            reason["step3"].push(
              `${this.getNow()}:${
                data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Number of photos less than ${
                config.photos.conf
              }":${data.photos}`
            );
          }
        }
        return {
          reason,
        };

      case 4:
        Object.keys(config).forEach((item) => {
          if (config[item].switch) {
            // description
            if (
              item === "description" &&
              data.description.length < Number(config.description.conf)
            ) {
              reason["step4"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${data.rego}:"Not enough Description":${
                  data.description.length
                }`
              );
            }

            // condition description
            if (
              item === "conditionDescription" &&
              data.conditionDescription.length <
                Number(config.conditionDescription.conf) &&
              (data.interior < 5 || data.exterior < 5)
            ) {
              reason["step4"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${data.rego}:"Not enough Condition Description":${
                  data.conditionDescription.length
                }`
              );
            }

            // interior
            if (
              item === "interior" &&
              data.interior < Number(config.interior.conf)
            ) {
              reason["step4"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${data.rego}:"Interior Condition not above":Good`
              );
            }

            // exterior
            if (
              item === "exterior" &&
              data.exterior < Number(config.exterior.conf)
            ) {
              reason["step4"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${data.rego}:"Exterior Condition not above":Good`
              );
            }

            // hail or flooding
            if (item === "hailOrFlooding" && data.hailOrFlooding === "Yes") {
              reason["step4"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${data.rego}:"Car identified as Hail/Flood damage":${
                  data.hailOrFlooding
                }`
              );
            }

            // warning lights or major mechanical issues
            if (item === "warningLights" && data.warningLights === "Yes") {
              reason["step4"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${
                  data.rego
                }:"Car identified as warning lights/mechanical issues":${
                  data.warningLights
                }`
              );
            }
          }
        });
        return {
          reason,
        };
      case 5:
        // valuation provided
        if (config.SSHigh.switch || config.SSLow.switch) {
          if (data.valuation === "") {
            reason["step5"].push(
              `${this.getNow()}:${
                data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Valuation not presented to the user"`
            );
          } else if (config.reservePrice.switch) {
            // reserve price
            // const [aboveLow, aboveHigh] = config.valuation.conf.split("-");
            const aboveLow = config.SSLow.conf;
            const aboveHigh = config.SSHigh.conf;
            const [priceLow, priceHigh] = data.valuation.split("-");
            const adjustedRangeHigh = aboveHigh !== 0 ?
              Number(priceHigh) * (Number(aboveHigh) / 100) : priceHigh;
            const adjustedRangeLow = aboveLow !== 0 ?
              Number(priceLow) * (Number(aboveLow) / 100) : priceLow;
            if (
              // Reserve Price > (HighPrice Estimate * %belowHigh)
              // Reserve Price < (LowPrice Estimate * %belowLow)
              (Number(data.reservePrice) > adjustedRangeHigh && config.SSHigh.switch) ||
              (Number(data.reservePrice) < adjustedRangeLow && config.SSLow.switch)
            ) {
              reason["step5"].push(
                `${this.getNow()}:${
                  data.location === "" ? "ZZZ" : data.location
                }:${
                  data.rego
                }:"Reserve price not within adjusted Range":${priceLow}:${priceHigh}:${data.reservePrice}`
              );
            }
          }
        }

        // sell now
        if (config.sell.switch) {
          if (data.sell !== "Now") {
            reason["step5"].push(
              `${this.getNow()}:${
                data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Not Selling Now"`
            );
          }
        }

        // pickUp
        if (config.pickUp.switch && data.sell === "Now") {
          if (!data.pickUp.toLowerCase().includes('within')) {
            reason["step5"].push(
              `${this.getNow()}:${
                data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Vehicle Not Available within ${
                config.pickUp.conf
              } week of Sale"`
            );
          }
        }
        return {
          reason,
        };

      default:
        // seller success fee
        if (config.sellerFees.switch) {
          if (!data.sellerFees && data.isQualified) {
            reason["step6"].push(
              `${this.getNow()}:${data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Seller fee box checked": No`
            )
          }
        }

        // seller term
        if (config.sellerTerms.switch) {
          if (!data.sellerTerms && data.isQualified) {
            reason["step6"].push(
              `${this.getNow()}:${data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Seller agreement box checked": No`
            )
          }
        }

        // written off
        if (config.writtenOff.switch) {
          if (data.writtenOff === "Yes") {
            reason["step6"].push(
              `${this.getNow()}:${
                data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Car identified as written off":${
                data.writtenOff
              }`
            );
          }
        }

        // stolen
        if (config.stolen.switch) {
          if (data.stolen === "Yes") {
            reason["step6"].push(
              `${this.getNow()}:${
                data.location === "" ? "ZZZ" : data.location
              }:${data.rego}:"Car identified as stolen":${
                data.stolen
              }`
            )
          }
        }
        return {
          reason,
        };
    }
  }
}

const qualifierService = new QualifierService();

export default qualifierService;
