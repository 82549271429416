import Vue from 'vue'
import Router from 'vue-router'
import modules from './modules'
import { flatten } from 'lodash-es'
import store from '@/store'
import { SET_INFO, SET_REGO_SEARCH_FLAG, RESET_OPTIONS, SET_REDBOOK_FLAG } from "@/store/info/actions";
import { ACTION_RESET_REGO_SEARCHED } from "@/store/rego-search/actions";
import { CLEAR_AUTHENTICATE_DATA } from "@/store/authenticate-seller/actions";
import { VUE_APP_BASE } from "@/config";

import { 
    CacheStatus, 
    CACHE_MODULE_NAME, 
    CACHE_STATUS, 
    CLEAR_STORE_CACHE 
} from '../store/plugins/session-storage-persistence'
import { eraseCookie, getCookie, setCookie } from '../helper/cookie'
import { isEmpty, logError } from "@/helper/utils";
import { SET_THEME_COLORS, SET_BROWSER_TAB } from "@/store/common-variables/actions";
import urlShortenerService from "@/services/url-shortener";
import { routers } from '@/helper/routers';

Vue.use(Router)
const router = new Router({
    base: VUE_APP_BASE,
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'top-page',
            component: () => import('../views/top-page/Index')
        },
        {
            path: '/',
            name: 'layout',
            component: () => import('../components/layout/Index'),
            children: [
                ...flatten(Object.values(modules))
            ]
        },
        {
            path: '/success',
            name: 'login-success',
            component: () => import('../views/universal-login/Success')
        },
        {
            path: '/*',
            name: 'not-found',
            component: () => import('../views/error/404')
        }
    ],
    scrollBehavior: scrollTop,
})


const route = {
    TOP_PAGE: "top-page",
    THANK_PAGE: "thanks-page",
};

function scrollTop(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
}

function resetStore(pathName) {
    if (pathName === route.TOP_PAGE) {
        store.dispatch("resetStep");
        store.dispatch("clearImages");
        store.dispatch(SET_INFO, {});
        store.dispatch(ACTION_RESET_REGO_SEARCHED);
        store.dispatch(CLEAR_AUTHENTICATE_DATA);
        store.dispatch(SET_REGO_SEARCH_FLAG, false);
        store.dispatch(RESET_OPTIONS);
        store.dispatch(SET_REDBOOK_FLAG, false);
        store.dispatch('setReminderPopupWasShown', false);
    }
    store.commit(CLEAR_STORE_CACHE);
}
    
function setNextStep(nextStep) {
    store.dispatch("setStep", nextStep);
}

function setCacheStatus(status) {
    store.state[CACHE_MODULE_NAME][CACHE_STATUS] = status;
}

function getShortedUrlCode(pathname) {
    return pathname.substring(pathname.lastIndexOf('/') + 1);
}

async function shortenedUrlProcessing() {
    try {
        const urlCode = getShortedUrlCode(window.location.pathname);
        if (
          urlCode !== "" &&
          !Object.values(routers).some(
            (router) => getShortedUrlCode(router) === urlCode
          )
        ) {
          const unShortenUrlResult = await urlShortenerService.unShortenTheUrl(
            urlCode
          );
          if (unShortenUrlResult.data)
            window.location.href = unShortenUrlResult.data;
        }
    } catch (error) {
        if (error.response.status !== 404) {
            logError(`[Router] shorten url error: ${error}`);
        }
    }

}

router.beforeEach(async (to, from, next) => {
    await shortenedUrlProcessing();
    if (!isEmpty(to.query)) {
        if (getCookie('qs'))
            eraseCookie('qs');
        setCookie('qs', new URLSearchParams(to.query).toString(), 1);
        window.localStorage.setItem('qs', JSON.stringify(Object.assign({}, to.query)));
    } else {
        eraseCookie("qs");
    }

    const excludeRouteNames = [
        "privacy-policy",
        "terms-and-conditions",
        "photo-guide",
        "seller-fees", 
        "seller-terms",
        "payment",
        "payment-finish",
        "login-success",
    ];
    
    const excludeToPath = [
        "top-page",
        "privacy-policy",
        "terms-and-conditions",
        "photo-guide",
        "seller-fees", 
        "seller-terms",
        "payment",
        "payment-finish",
        "login-success",
    ];

    if (excludeRouteNames.includes(to.name)) {
        next();
        return;
    }

    const lookupValue = "0";

    switch (store.state[CACHE_MODULE_NAME][CACHE_STATUS]) {
        case CacheStatus.New:
            // console.log(CacheStatus.New, Date.now());
            if (lookupValue !== '0') {
                resetStore(to.name);
            }
            setCacheStatus(CacheStatus.Rehydrated);
            if (to.name === route.TOP_PAGE || to.name === route.THANK_PAGE) {
                next({ name: "top-page", query: to.query });
            } else {
                next({
                    name: store.getters.currentStep.name,
                    query: from.query,
                });
            }
        break;

        case CacheStatus.Empty:
            setCacheStatus(CacheStatus.New);
            next({ name: "top-page", query: to.query });
        break;

        default:
        if (to.name === route.TOP_PAGE || to.name === route.THANK_PAGE) {
            resetStore(to.name);
            next();
        } else if (
            from.name === "thanks-page" &&
            !excludeToPath.includes(to.name)
        ) {
            next({ name: "top-page" });
        } else {
            // check step
            if (to.meta.nextable) {
                next();
            } else {
                setNextStep(to.meta.step);
                if (from.name === "top-page" && to.name === "navigation") {
                    store.dispatch("setProgress", 0);
                    store.dispatch("resetProgressStep");
                }
                next();
            }
        }
        break;
    }
});

router.afterEach((to) => {
    store.dispatch(SET_THEME_COLORS);
    if (to.name !== route.TOP_PAGE) {
        store.dispatch(SET_BROWSER_TAB);
    }
})

export default router;
