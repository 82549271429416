import { GET_NZ_VARIABLES, RESET_VARIABLES, SET_BROWSER_TAB, SET_THEME_COLORS } from "./actions";
import { GET_NZ_VARIABLES_SUCCESS, RESET_VARIABLES_SUCCESS, SET_BROWSER_TAB_SUCCESS, SET_THEME_COLORS_SUCCESS } from "./mutations";
import NZService from "@/services/nz-service";
import { setFavicon, setTitle } from "../../helper/utils";

// Alway define initial arrays as separate variables and map to store only the first time call action to update the store
// It will prevent array duplicate issue in Vuex
const initialWhatToExpect = {
  text: [
    "Before your car is listed, the AutoFlip team will review your submitted information.",
    "We'll contact you in 1-2 business days if we need more photos or details of your car before it's listed.",
    "Once we have all the required details, we'll list your car to our large dealer network to get you the best price, fast!"
  ],
  textLink: "To learn more about how AutoFlip works, click {here}.",
  links: [
    {
      "name": "here",
      "link": "https://www.autoflip.com.au/how-it-works"
    }
  ]
};

const initialPackages = [
  {
    id: "package-1",
    name: "Lite",
    featureTitle: "Lite plan includes:",
    featureList: [
      "Free to list",
      "Negotiating with buyers",
      "Promoting your listing to buyers"
    ],
    price: 0,
    state: [
      "QLD",
      "NSW",
      "ACT",
      "VIC",
      "TAS",
      "WA",
      "SA",
      "NT"
    ],
    isExpanded: true
  },
  {
    id: "package-2",
    name: "Standard",
    featureTitle: "Lite plus:",
    featureList: [
      "Highlighted listing",
      "Up to 2 free relistings",
      "AI condition report"
    ],
    price: 29,
    state: [
      "QLD",
      "NSW",
      "ACT",
      "VIC",
      "TAS",
      "WA",
      "SA",
      "NT"
    ],
    isExpanded: true
  },
  {
    id: "package-3",
    name: "Premium",
    featureTitle: "Standard plus:",
    featureList: [
      "Priority listing placement",
      "Vehicle history report",
      "PPSR report added to your listing"
    ],
    price: 89,
    state: [
      "QLD",
      "NSW",
      "ACT",
      "VIC",
      "TAS",
      "WA",
      "SA",
      "NT"
    ],
    isExpanded: true
  }
]

const initialErrorsMailboxes = [
  "LPErrors@idomi.com.au",
  "dmv.01.test@gmail.com"
]

const initialState = {
  sharedMailboxes: {
    reportIssues: "testleadupdates@idomi.com.au",
    unsubscribeEmail: "privacy@autoflip.com.au",
    errorsMailbox: [],
  },
  socialLinks: {
    facebook: "https://www.facebook.com/autoflipaustralia",
    instagram: "https://www.instagram.com/autoflipau",
    twitter: "https://www.twitter.com/AutoflipAU",
    linkedIn: "https://www.linkedin.com/company/autoflip-australia",
    youtube: "https://www.youtube.com/@autoflip6451",
    socialMediaIcons: true
  },
  contactDetails: {
    phoneNumber: "1300 816 821",
    email: "info@autoflip.com.au",
    mailIcon: "https://th.bing.com/th/id/R.3cc4d71ef0d49b1de9771d3d2159a57d?rik=4DhEKOaV9Xy05w&riu=http%3a%2f%2fpluspng.com%2fimg-png%2femail-icon-png-email-icon-image-122-2400.png&ehk=ysN53EM4TZXnpAYPdVx4gyPi9yAxhixiW8uwFiA1CvM%3d&risl=&pid=ImgRaw&r=0",
    phoneIcon: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_telphone.svg"
  },
  policyDocumentsLinks: {
    sellerAgreement: "https://www.autoflip.com.au/seller-agreement",
    successFee: "https://www.autoflip.com.au/seller-fees",
    termsAndConditions: "https://www.autoflip.com.au/terms-and-conditions",
    privacyPolicy: "https://www.autoflip.com.au/privacy-policy"
  },
  photoGuideLink: {
    fromLandingPage: "https://www.autoflip.com.au/photo-guide",
    fromMyCar: "https://www.autoflip.com.au/photo-guide"
  },
  businessNumber: "ABN: 87 638 825 751",
  myCarLinks: {
    buildDate: "https://www.autoflip.com.au/find-build-date",
    troubleLogging: "https://www.autoflip.com.au/login-issues",
    guidedCamera: "https://www.autoflip.com.au/guided-camera"
  },
  homepageLink: "https://www.autoflip.com.au",
  footer: {
    footerText: "AutoFlip connects private sellers of cars with our large trusted wholesale buying network, Australia-wide.",
    howItWorks: "https://www.autoflip.com.au/how-it-works",
    forDealers: "https://www.autoflip.com.au/dealers",
    forEnterprise: "https://www.autoflip.com.au/enterprise",
    aboutUs: "https://www.autoflip.com.au/about",
    faq: "https://www.autoflip.com.au/faqs",
    contact: "https://www.autoflip.com.au/contact",
    leaveReviewLink: "https://search.google.com/local/writereview?placeid=ChIJkc4mz8lD1moR5gb_xY6M61I",
    googleReview: "https://www.google.com/search?q=autoflip&rlz=1C1GCEA_enAU1009AU1010&oq=auto&aqs=chrome.1.69i60j69i59l2j69i57j69i60l2j69i65j69i60.2288j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad643c9cf26ce91:0x52eb8c8ec5ff06e6,1,,,",
    googleReviewBadge: "https://idom-seller.s3.ap-southeast-2.amazonaws.com/storage/images-uploads-staging/MicrosoftTeams-image.png"
  },
  autoflipLogo: "https://www.autoflip.com.au",
  emailReviewBadge: "https://idom-seller.s3.ap-southeast-2.amazonaws.com/storage/images-uploads-staging/MicrosoftTeams-image.png",
  footerBooleans: {
    googleReviewRating: false,
    howItWorks: true,
    forDealers: true,
    forEnterprise: true,
    aboutUs: true,
    contact: true,
    faq: true,
    howWeWork: true
  },
  logo: {
    logoLink: "https://www.autoflip.com.au",
    logoHeaderTopPage: "https://idom-images.s3.ap-southeast-2.amazonaws.com/logo_header_and_footer.svg",
    logoHeaderAndFooter: "https://idom-images.s3.ap-southeast-2.amazonaws.com/logo_header_and_footer.svg"
  },
  copyBlocks: {
    valuationDisclaimer: "The price guide is created using independent market data. It is indicative only and relies on the vehicle information provided at the time of submission. Details such as vehicle specification, number of kilometres, and condition of vehicle all impact the price guide. The final sale price may differ and will depend on market conditions and demand from our dealer network. AutoFlip is not responsible for any errors provided or omissions of information.***",
    marketingCheckbox: "Receive occasional promotional communications from AutoFlip. We will not spam you and you can opt out at any time.**",
    feesAgreementsCheckbox: {
      text: "I agree to and acknowledge AutoFlip's seller {0} and {1} - NO DEAL, NO FEE",
      links: [
        {
          name: "agreement",
          link: "https://www.autoflip.com.au/seller-agreement"
        },
        {
          name: "success fee",
          link: "https://www.autoflip.com.au/seller-fees"
        }
      ],
      active: true,
    },
    thanksPageHeader: "Thank you {customerName} for your enquiry on AutoFlip.",
    whatToExpect: {},
    photoTipsBanner: {
      photoText: "The more photos you add, the easier it is to sell your car. For best results, we recommend adding at least 15 photos of your car.TEST",
      photoTextLink: "Tips for taking great photos",
      photoLink: "https://ws-staging.idomitech.net/photo-guide"
    },
    quickTipsBanner: {
      tipsHeader: "QUICK TIPs",
      tipsContent: "Don't forget to add photos of your odometer, tyres and any damagesSS."
    }
  },
  theme: {
    primaryColor: "#1F8252",
    subHeaderText: "#00bb65",
    photoUploadIcons: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_photo_upload.svg",
    starRating: "#00BC65",
    photoTipsBanner: {
      text: "#3AAC5D",
      background: "#E1F6ED",
      border: "#0AB76D",
      textLink: "#3AAC5D"
    },
    quickTipsBanner: {
      text: "#463209",
      background: "#FFF6ED",
      border: "#F5CE84"
    },
    addMorePhotosBanner: {
      text: "#333",
      background: "#82d6a3",
      border: "#1f8252"
    },
    carConditionsThanksPage: {
      ownersManual: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_logbook.png",
      serviceHistory: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_history.png",
      keys: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_service.png",
    },
    coverPhotoBorder: "#05b36d",
    emailStarRating: "https://idom-images.s3.ap-southeast-2.amazonaws.com/lexus-star-fill.png",
    otherPhotoUploadIcons: "https://idom-images.s3.ap-southeast-2.amazonaws.com/add_photo.svg",
    secondaryColor: "#1f825280",
    successFee: {
      text: "#404040",
      link: "#1f8252",
      background: "#d1e8d5"
    }
  },
  successFee: {
    active: true,
    link: "https://www.autoflip.com.au/seller-fees"
  },
  heroImage: "https://idom-images.s3.ap-southeast-2.amazonaws.com/hero-image.png",
  uxeId: "",
  brandName: "AutoFlip",
  packages: [],
  listingTiers: true,
};

const state = { 
  ...initialState,
};

const getters = {
  uxeId(state) {
    return state.uxeId;
  },
};

const actions = {
  [GET_NZ_VARIABLES](context, params) {
    return new Promise((resolve, reject) => {
      context.commit('setLoadingScreen', true);
      NZService.getEnvironmentVariables(params)
        .then(({ data }) => {
          context.commit(GET_NZ_VARIABLES_SUCCESS, { ...data.variablesConfig, uxeId: data.uxeId });
          context.commit(SET_THEME_COLORS_SUCCESS);
          context.commit(SET_BROWSER_TAB_SUCCESS);
          context.commit('setLoadingScreen', false);
          resolve();
        })
        .catch((err) => {
          context.commit('setLoadingScreen', false);
          reject(err);
        });
    });
  },
  [RESET_VARIABLES](context) {
    context.commit(RESET_VARIABLES_SUCCESS);
  },
  [SET_THEME_COLORS](context) {
    context.commit(SET_THEME_COLORS_SUCCESS);
  },
  [SET_BROWSER_TAB](context) {
    context.commit(SET_BROWSER_TAB_SUCCESS);
  },
};

const mutations = {
  [GET_NZ_VARIABLES_SUCCESS](state, payload) {
    state.sharedMailboxes = {
      ...payload.sharedMailboxes,
      errorsMailbox:
        payload.sharedMailboxes?.errorsMailbox || initialErrorsMailboxes,
    };
    state.socialLinks = payload.socialLinks;
    state.contactDetails = payload.contactDetails;
    state.policyDocumentsLinks = payload.policyDocumentsLinks;
    state.photoGuideLink = payload.photoGuideLink;
    state.myCarLinks = payload.myCarLinks;
    state.footerBooleans = payload.footerBooleans;
    state.footer = payload.footer;
    state.homepageLink = payload.homepageLink;
    state.logo = payload.logo;
    state.businessNumber = payload.businessNumber;
    state.emailReviewBagde = payload.emailReviewBagde;
    state.autoflipLogo = payload.autoflipLogo;
    state.copyBlocks = {
      ...payload.copyBlocks,
      whatToExpect: payload.copyBlocks?.whatToExpect || initialWhatToExpect,
    };
    state.theme = payload.theme;
    state.successFee = payload.successFee;
    state.heroImage = payload.heroImage;
    state.uxeId = payload.uxeId;
    state.browserTab = payload.browserTab;
    state.brandName = payload.brandName;
    state.packages = payload.packages || initialPackages;
    state.listingTiers = payload.listingTiers;
  },
  [RESET_VARIABLES_SUCCESS](state) {
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
  [SET_THEME_COLORS_SUCCESS](state) {
    const themeColors = {
      '--primary-color': state.theme.primaryColor,
      '--sub-header-text-color': state.theme.subHeaderText,
      '--cover-photo-border': state.theme.coverPhotoBorder,
      '--star-rating-color': state.theme.starRating,
      '--secondary-color': state.theme.secondaryColor,
      '--photo-tips-text': state.theme.photoTipsBanner.text,
      '--photo-tips-text-link': state.theme.photoTipsBanner.textLink,
      '--photo-tips-background': state.theme.photoTipsBanner.background,
      '--photo-tips-border': state.theme.photoTipsBanner.border,
      '--quick-tip-text': state.theme.quickTipsBanner.text,
      '--quick-tip-background': state.theme.quickTipsBanner.background,
      '--quick-tip-border': state.theme.quickTipsBanner.border,
      '--add-more-photo-banner-text': state.theme.addMorePhotosBanner.text,
      '--add-more-photo-banner-background': state.theme.addMorePhotosBanner.background,
      '--add-more-photo-banner-border': state.theme.addMorePhotosBanner.border,
      '--success-fee-text-color': state.theme.successFee.text,
      '--success-fee-link-color': state.theme.successFee.link,
      '--success-fee-background-color': state.theme.successFee.background,
    }

    for (const [themeProperty, themeValue] of Object.entries(themeColors)) {
      document.documentElement.style.setProperty(themeProperty, themeValue);
    }
  },
  [SET_BROWSER_TAB_SUCCESS](state) {
    if (state.browserTab) {
      setFavicon(state.browserTab.favicon);
      setTitle(state.browserTab.title);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};