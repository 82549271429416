import webApi from "@/services/api"

export class CustomerDetailService {
    carDetail(data) {
        return webApi.post('car-detail', data)
    }
    getPriceRange(params) {
        return webApi.get('car-detail/price-range-process/car-info-redbook-october/autoflip', { params })
    }
    getListLocations() {
        return webApi.get('location-service')
    }
    getAgeRegistration(params) {
        return webApi.get('car-detail/nevdis-api', { params })
    }
    getRedbookDataMapper() {
        return webApi.get('car-detail/redbook-data-mapper')
    }
    getWriteOffAndStolen(params) {
        return webApi.get('car-detail/nevdis-api/write-off-and-stolen', { params })
    }
    saveActionLog(data) {
        return webApi.post('car-detail/save-psp-action-log', data)
    }
    getWriteOffAndStolenStatus(params) {
        return webApi.get('car-detail/get-psp-data-logs', { params })
    }
    getSuccessFee(params) {
        return webApi.get('seller-fee', { params })
    }
}

const customerDetailService = new CustomerDetailService()

export default customerDetailService
