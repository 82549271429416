export const FETCH_DETAIL = 'FETCH_DETAIL'
export const FETCH_MODELS = 'FETCH_MODELS'
export const FETCH_MAKES = 'FETCH_MAKES'
export const FETCH_YEARS = 'FETCH_YEARS'
export const FETCH_BADGES = 'FETCH_BADGES'
export const SET_BASIC_INFO = 'SET_BASIC_INFO'
export const SET_INFO = 'SET_INFO'
export const FETCH_ALL = 'FETCH_ALL'
export const SET_PREV_LENGTH = 'SET_PREV_LENGTH'
// export const CLEAR_SERVICE_HISTORY_DETAIL = 'CLEAR_SERVICE_HISTORY_DETAIL'
export const SET_REGO_SEARCH_FLAG = 'SET_REGO_SEARCH_FLAG'
export const SET_STATE = 'SET_STATE'
export const FETCH_INIT = 'FETCH_INIT'
export const RESET_OPTIONS = 'RESET_OPTIONS'
export const SET_REDBOOK_FLAG = 'SET_REDBOOK_FLAG'
export const SET_OTHER_OPTIONS = 'SET_OTHER_OPTIONS'
