import { routers } from '@/helper/routers';

export default [
  {
    path: routers.PAYMENT,
    name: 'payment',
    component: () => import('@/views/payment/Index'), 
  },
  {
    path: routers.PAYMENT_FINISH,
    name: 'payment-finish',
    component: () => import('@/views/payment/Finish'), 
  },
]