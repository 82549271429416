<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
@import "./assets/css/common.css";
#app {
  font-family: Avenir Next LT W05 Demi, Avenir Next LT W05 Demi Italic, Avenir Next LT W05 Bold, Avenir Next LT W05 Medium, Avenir Next LT W05 Italic,
                Avenir Next LT W05 Regular, Avenir Next LT W05 Ultra Light, Avenir Next LT W05 Medium It, Avenir Next LT W05 Ult Light I,
                Avenir Next LT W05 Thin, Avenir Next LT W05 Thin Italic, Avenir Next LT W05 Heavy, Avenir Next LT W05 Heavy Itali,
                Avenir Next LT W05 Light, Avenir Next LT W05 Light Itali, Avenir Next LT W05 Bold Italic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}
</style>
