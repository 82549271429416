export const routers = {
  VEHICLE_INFO: "vehicle-info",
  REGO_VEHICLE_INFO: "rego/vehicle-info",
  PHOTO_UPLOAD: "photo/upload",
  PHOTO: "photo",
  CONDITION_INFO: "condition-info",
  NAVIGATION: "navigation",
  SALE_INFO: "review",
  PAYMENT: "payment",
  PAYMENT_FINISH: "payment/finish",
  PHOTO_GUIDE: "photo-guide",
  THANKS_PAGE: "thanks",
  LOGIN_SUCCESS: "success",
};