import axios from "axios"
import { API_ENDPOINT, AUTH_URI } from "../config"
import store from '@/store';
import Vue from "vue"

// Set the max number of retries
const MAX_RETRIES = 3;

const webApi = axios.create({
    baseURL: API_ENDPOINT,
    timeout: 0,
})
webApi.interceptors.request.use(
    async config => {
        if (!store.getters.token)
            await store.dispatch('fetchToken');
        const token = store.getters.token;
        if (config.url !== AUTH_URI) {
            config.headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

webApi.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const config = error.config;

        // Check if the retry count is defined, if not, initialize it
        if (!config.__retryCount) {
            config.__retryCount = 0;
        }

        if (config && error.response && error.response.status === 401) {
            await store.dispatch('fetchToken');
            const token = store.getters.token;
            // successfully refresh token
            if (token) {
                const originalRequest = config;
                originalRequest.headers = {
                    Authorization: `Bearer ${token}`
                }
                const formDataOrUndefined = originalRequest.data instanceof FormData || !originalRequest.data;
                originalRequest.data = !formDataOrUndefined
                    ? JSON.parse(originalRequest.data)
                    : originalRequest.data;
                window.localStorage.setItem('token', token)
                return webApi(originalRequest);
            }
        }
        // If there is a network error or timeout, and retry count is less than max retries, retry the request
        if ((error.code === "ECONNABORTED" || error.message.includes("Network Error")) &&
            /\/car-detail\/image(\/.*)?$/.test(config.url)
        ) {
            if (config.__retryCount < MAX_RETRIES) {
                config.__retryCount += 1;
                console.log(`Retry attempt #${config.__retryCount}`);

                // Delay the retry by 1 second before the next attempt
                await new Promise((resolve) => setTimeout(resolve, 5000));

                return webApi(config);
            }
        }

        if (!error.response && !config.url.includes("/car-detail/image")) {
            const message = 'Failed to submit information. Please wait for a while and try again.'
            Vue.$toast.clear()
            Vue.$toast.error(message)
        }
        return Promise.reject(error)
    }
)

export default webApi
