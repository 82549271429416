import webApi from "./api"

export class UrlShortenerService {
	async unShortenTheUrl(urlCode) {
		return webApi.get(`un-shorten/${urlCode}`)
	}
}

const urlShortenerService = new UrlShortenerService()

export default urlShortenerService