import VehicleInfo from './vehicle-info'
import ConditionInfo from "./condition-info";
import PhotoUpload from './photo-upload'
import CustomerDetail from './customer-detail'
import ThanksPage from './thanks-page'
import PhotoGuide from './photo-guide'
import Contact from './contact'
import Payment from './payment'
import NavigationPage from './navigation-page'

export default {
  VehicleInfo,
  ConditionInfo,
  PhotoUpload,
  CustomerDetail,
  ThanksPage,
  PhotoGuide,
  Contact,
  Payment,
  NavigationPage,
};
