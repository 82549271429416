import { routers } from '@/helper/routers';

export default [
  {
    path: routers.NAVIGATION,
    name: 'navigation',
    component: () => import('@/views/navigation-page/Index'),
    meta: {
      step: 1,
    }
  },
]